:root {
    --textColor: rgb(7, 5, 3);
    --bgColor: rgb(216, 220, 196);
}

.App {
    text-align: center;
    background-image: url("bg.png");
    background-repeat: repeat;
}

.content {
    min-height: 100vh;
    /* font-size: 18px; */
    font-size: calc(10px + 2vmin);
    color: --var(textColor);
}

h1 {
    font-size: 2.25em;
}

h2 {
    font-size: 1.25em;
}

h3 {
    font-size: 1em;
}

p {
    font-size: 1em;
}

a:visited {
    color: rgb(86, 0, 224);
}

a {
    color: rgb(0, 140, 255);
    text-decoration: none;
}

/* column content */
.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.pre-header {
    flex-grow: 1;
}

.post-footer {
    flex-grow: 1;
    display: flex;
}

/* images/text */
.logo-img {
    max-height: 80vmin;
    pointer-events: none;
}

.link-img {
    width: .5em;
    margin-left: 4px;
    vertical-align: middle;
}

.privacy-text {
    align-self: flex-end;
    font-size: .65em;
}

/* socials */
.socials-logo {
    width: 2em;
    filter: drop-shadow(4px 4px 2px rgb(153, 143, 122));
}

.socials-container {
    display: flex;
    flex-grow: 1;
}

.socials-col {
    width: 5em;
}