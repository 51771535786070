.sp-bot-8 {
    margin-bottom: 8px;
}

.sp-bot-16 {
    margin-bottom: 16px;
}

.sp-bot-32 {
    margin-bottom: 32px;
}

.sp-bot-64 {
    margin-bottom: 64px;
}